import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import RaiseOperativeRequestIconButton from '../../../../support/components/buttons/RaiseOperativeRequestIconButton';

// in the first version the header components
// OrderingOffersHeader, SimulationsHeader, OrderingCollectionsHeaders and OrderingOperationsHeaders
// are exactly the same, but in the future thay could have different acctions
const OrderingOperationsHeader = ({ selectedPurchaseOrderIds }) => (
  <Grid>
    <RaiseOperativeRequestIconButton
      documentType="ordering"
      documentIds={selectedPurchaseOrderIds}
    />
  </Grid>
);

OrderingOperationsHeader.propTypes = {
  selectedPurchaseOrderIds: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default OrderingOperationsHeader;
