import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const AddInvoicesToPurchaseOrdersDialogStepThree = () => (
  <Stack spacing={1} alignItems="center">
    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>Tu evaluación de ordering se ha enviado con éxito</Typography>
    <Typography fontSize={100}>
      <CheckCircleOutlineIcon fontSize="inherit" />
    </Typography>
    <Typography sx={{ fontSize: 12, maxWidth: 0.75, textAlign: 'center' }}>
      Recibirás un correo con la respuesta en cuanto
      nuestro equipo de riesgo haya evaluado la operación
    </Typography>
  </Stack>
);

export default AddInvoicesToPurchaseOrdersDialogStepThree;
