import React from 'react';
import CreateCampaignAction from '../actions/CreateCampaign';

const MarketingCampaignsAction = () => (
  <>
    <CreateCampaignAction />
  </>
);

export default MarketingCampaignsAction;
