import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useParams } from 'react-router-dom';
import useMarketingCampaignContactColumns from '../../../../columns/useMarketingContactColumns';
import { MARKETING_CAMPAIGN_CONTACTS } from '../../graphql/marketingCampaign/queries';

const MarketingCampaignProfile = () => {
  const { campaignId } = useParams();
  return (
    <FingoMainView
      id="marketing-campaign-contact-view"
      query={MARKETING_CAMPAIGN_CONTACTS}
      queryCustomVariables={{
        marketingCampaign_Id: campaignId,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: `Campaña ID ${campaignId}`,
          finder: {
            searchPlaceHolder: 'Buscar',
          },
          showGoBack: true,
        },
        table: {
          columns: useMarketingCampaignContactColumns(),
          noRowsMessage: () => <Typography>No hay contactos</Typography>,
          initialOrderBy: 'contact_Email',
          checkboxSelection: false,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};
export default MarketingCampaignProfile;
