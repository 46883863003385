import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import useMarketingCampaignColumns from '../../../../columns/useMarketingCampaignColumns';
import { MARKETING_CAMPAIGNS, MARKETING_CAMPAIGN_SUMMARY } from '../../graphql/marketingCampaign/queries';
import MarketingCampaignsAction from './components/MarketingCampaignsAction';

const useMarketingSummaryColumns = () => [
  {
    id: 'event',
    field: 'event',
    headerName: 'Evento',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.event}
      </Typography>
    ),
  },
  {
    id: 'count',
    field: 'count',
    headerName: 'Cantidad',
    type: 'string',
    sortable: true,
    filterable: false,
    flex: 1,
    renderCell: (params) => (
      <Typography
        variant="body1"
      >
        {params.row.count}
      </Typography>
    ),
  },
];

const CollapsibleComponent = ({ row }) => {
  const [orderBy, setOrderBy] = useState('event');
  const { data, loading } = useQuery(MARKETING_CAMPAIGN_SUMMARY, {
    variables: {
      marketingCampaignId: row.id,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });
  const rows = useMemo(() => {
    const rawRows = data?.marketingCampaignSummary || [];
    return rawRows;
  }, [data]);
  const rowHeight = 50;
  return (
    <Box height={250}>
      <FingoDataGrid
        columns={useMarketingSummaryColumns()}
        rows={rows}
        hideFooter
        loadingWithSkeleton={loading}
        serverFilters={false}
        rowHeight={rowHeight}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        getRowId={(_row) => _row.event}
      />
    </Box>
  );
};

CollapsibleComponent.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const NoRows = () => <Typography>No hay camapañas con estos parámetros</Typography>;
const MarketingCampaign = () => (
  <FingoMainView
    id="marketing-campaigns"
    query={MARKETING_CAMPAIGNS}
    slots={{
      header: PaperHeader,
      table: FingoTable,
      actions: MarketingCampaignsAction,
    }}
    slotProps={{
      header: {
        viewTitle: 'Campañas de marketing',
        finder: {
          searchPlaceHolder: 'Buscar',
        },
      },
      table: {
        columns: useMarketingCampaignColumns(),
        includeHeaders: [],
        noRowsMessage: NoRows,
        initialOrderBy: '-id',
        checkboxSelection: false,
        rowsPerPageOptions: [10, 25, 50, 100],
        collapsible: true,
        isRowCollapsible: useCallback(() => true, []),
        collapseComponent: CollapsibleComponent,
      },
    }}
  />
);

export default MarketingCampaign;
