import React from 'react';
import { FadeSwitch } from '@fingo/lib/components/switches';
import { Route, useRouteMatch } from 'react-router-dom';
import MarketingCampaign from './MarketingCampaigns';
import MarketingCampaignProfile from './MarketingCampaignProfile';

const MarketingCampaignSwitch = () => {
  const routePath = useRouteMatch();
  return (
    <FadeSwitch depth={5}>
      <Route id="profile" key="profile" path={`${routePath.path}/:campaignId`}>
        <MarketingCampaignProfile />
      </Route>
      <Route id="index" key="index" path={`${routePath.path}`}>
        <MarketingCampaign />
      </Route>
    </FadeSwitch>
  );
};

export default MarketingCampaignSwitch;
