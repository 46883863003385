import { useMutation, useQuery } from '@apollo/client';
import { MAILS } from '@fingo/lib/graphql';
import { CREATE_MARKETING_CAMPAIGN } from '../../../graphql/marketingCampaign/mutations';
import { MARKETING_CAMPAIGNS } from '../../../graphql/marketingCampaign/queries';

const useCreateMarketingCampaign = (
  {
    sendingDate,
    mailId,
    emailNameSearch,
    contacts,
    countryId,
  },
  onCompleted = () => {},
  onError = () => {},
  skip = false,
) => {
  const result = useMutation(CREATE_MARKETING_CAMPAIGN, {
    variables: {
      sendingDate,
      mailId,
      contacts,
      countryId,
    },
    refetchQueries: [MARKETING_CAMPAIGNS],
    onCompleted,
    onError,
  });
  const mailResult = useQuery(MAILS, {
    variables: {
      emailName_Icontains: emailNameSearch,
      orderBy: 'emailName',
      first: 100,
    },
    skip,
  });
  const mails = mailResult.data?.mails.edges.map((edge) => edge.node) || [];
  const lastCursor = mailResult.data?.mails.pageInfo.endCursor;
  return [result, { ...mailResult, mails, lastCursor }];
};

export default useCreateMarketingCampaign;
