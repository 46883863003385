import { OrderingOperationPaymentSummary } from '@fingo/lib/components/dataDisplay';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import FingoTable from '@fingo/lib/components/tables/FingoTable';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import { orderStatus } from '@fingo/lib/helpers';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import OrderingCollectionsHeader from './headers/OrderingCollectionsHeader';

const CollapsibleComponent = ({ row }) => (
  <OrderingOperationPaymentSummary purchaseOrder={row} />
);

CollapsibleComponent.propTypes = {
  row: PropTypes.shape().isRequired,
};

const OrderingCollectionsManager = () => {
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
  return (
    <FingoMainView
      id="ordering-operations"
      query={ORDERING_PURCHASE_ORDERS}
      queryCustomVariables={{
        inCollection: true,
        companyId: null,
      }}
      slots={{
        header: PaperHeader,
        actions: OrderingCollectionsHeader,
        table: FingoTable,
      }}
      slotProps={{
        header: {
          viewTitle: 'Operaciones activas e históricas',
          finder: {
            searchPlaceHolder: 'Búsqueda por folio, rut o nombres',
            canPasteExcel: true,
            setColumnFilter: () => null,
          },
        },
        actions: {
          selectedPurchaseOrderIds,
          setSelectedPurchaseOrderIds,
        },
        table: {
          columns: useOrderingLucilaPreColumns(),
          includeHeaders: [
            'orderNumber',
            'company_MasterEntity_Name',
            'purchaserName',
            'publicationDate',
            'orderingoffer_OrderingFinancedAmount',
            'totalAmount',
            'orderingoffer_InvoiceIssuedDate',
            'orderingStatus',
            'actionsDrawer',
          ],
          checkboxSelection: true,
          isRowSelectable: ({ row }) => row.status === orderStatus.PendingInvoices,
          collapsible: true,
          isRowCollapsible: ({ row }) => !!row.invoiceAssignationRecords.length
            || !!row.orderingsenttocollectionregisterSet.length,
          collapseComponent: CollapsibleComponent,
          disableSelectionOnClick: true,
          onSelectionModelChange: (ids) => { setSelectedPurchaseOrderIds(ids); },
          selectionModel: selectedPurchaseOrderIds,
          density: 'compact',
          noRowsMessage: () => <Typography>No hay OCs cargadas.</Typography>,
          initialOrderBy: '-status',
          rowsPerPageOptions: [5, 10, 25],
        },
      }}
    />
  );
};

export default OrderingCollectionsManager;
