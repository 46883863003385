import { DocumentList } from '@fingo/lib/components/lists';
import { useOrderingLucilaPreColumns } from '@fingo/lib/constants';
import { ORDERING_PURCHASE_ORDERS } from '@fingo/lib/graphql';
import React, { useState } from 'react';
import OrderingOperationsHeader from './headers/OrderingOperationsHeader';

const OrderingOperationsManager = () => {
  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState([]);
  return (
    <DocumentList
      trackerId="ORDERING_PREOFFER"
      type="ordering"
      headerTitle="Operaciones de ordering"
      queryDocument={ORDERING_PURCHASE_ORDERS}
      emitter
      customVariables={{
        inOperationActive: true,
        companyId: null,
      }}
      includeHeaders={[
        'orderNumber',
        'company_MasterEntity_Name',
        'purchaser_Name',
        'publicationDate',
        'totalAmount',
        'status',
      ]}
      mobileHeaders={[
        'orderNumber',
        'purchaser_Name',
        'totalAmount',
        'orderingsimulation_OrderingMonthlyRate',
        'orderingInvoiceDate',
      ]}
      checkboxSelection
      showFilters
      defaultFilterProps={{
        showStatesFilter: false,
        showDatesFilter: false,
        showCompanyIssuerFilter: true,
        defaultFilterDays: 30,
      }}
      flexEndButtons={() => (
        <OrderingOperationsHeader
          selectedPurchaseOrderIds={selectedPurchaseOrderIds}
        />
      )}
      selectionModel={selectedPurchaseOrderIds}
      onSelectionModelChange={(ids) => setSelectedPurchaseOrderIds(ids)}
      onCompletedSetLength={(data) => data.purchaseOrders.totalCount}
      onCompletedSetRows={(data) => data.purchaseOrders.edges.map((edge) => (edge.node))}
      preColumns={useOrderingLucilaPreColumns()}
      initialOrderBy="-publicationDate"
    />
  );
};

export default OrderingOperationsManager;
