import React, { useCallback, useState } from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useGetCountries, useSnackBars } from '@fingo/lib/hooks';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MenuButton from '@fingo/lib/components/buttons/MenuButton';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import FingoDateTimePicker from '@fingo/lib/components/datePickers/FingoDateTimePicker';
import useFileState from '@fingo/lib/hooks/useFileState';
import Clear from '@mui/icons-material/Clear';
import FileDownload from '@mui/icons-material/FileDownload';
import useExcelTemplateDownloader from '@fingo/lib/hooks/useExcelTemplateDownloader';
import useCreateMarketingCampaign from '../hooks/useCreateMarketingCampaign';

const CreateCampaignAction = () => {
  const [open, toggleOpen] = useBooleanState(false);
  const [selectedMailId, setSelectedMailId] = useState('');
  const [selectedCountryId, setSelectedCountryId] = useState('');
  const [sendingDate, setSendingDate] = useState('');
  const [emailNameSearch, setEmailNameSearch] = useState('');
  const [contacts, setContacts, clearContacts] = useFileState();
  const { addAlert } = useSnackBars();
  const countries = useGetCountries();
  const reset = useCallback(() => {
    toggleOpen();
    setSelectedMailId('');
    setSendingDate('');
    clearContacts();
  }, []);
  const [downloadExcel] = useExcelTemplateDownloader('marketing-campaign-template');
  const downloadTemplate = () => downloadExcel({
    variables: {
      columns: ['rut', 'email'],
      descriptions: ['77201556', 'andres.lopez@fingo.cl'],
    },
  });
  const [
    [createMarketingCampaign, { loading: loadingCreateMarketingCampaign }],
    { mails },
  ] = useCreateMarketingCampaign(
    {
      sendingDate,
      mailId: selectedMailId,
      emailNameSearch,
      contacts,
      countryId: selectedCountryId,
    },
    reset,
    (error) => {
      addAlert({
        id: 'marketing-creation-error',
        message: `Error: ${error.message}`,
      });
    },
    !open,
  );
  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={toggleOpen}
      >
        Crear campaña nueva
      </Button>
      <FingoDialog
        open={open}
        title="Nueva campaña masiva de marketing"
        handleClose={toggleOpen}
        maxWidth="sm"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            type="submit"
            loading={loadingCreateMarketingCampaign}
            id="create-marketing-campaign"
            onClick={createMarketingCampaign}
          >
            Crear
          </LoadingButton>
      )}
      >
        <form onSubmit={createMarketingCampaign}>
          <Stack spacing={4} alignItems="center">
            <FingoDateTimePicker
              disablePast
              onChange={setSendingDate}
              value={sendingDate}
            />
            <Autocomplete
              fullWidth
              id="select-mail-mkt-campaign"
              options={mails}
              value={selectedMailId}
              onChange={(event, newValue) => {
                setSelectedMailId(newValue.id);
              }}
              filterOptions={(options, params) => {
                setEmailNameSearch(params.inputValue);
                return options;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Elija el tipo de mail" variant="outlined" />
              )}
            />
            <MenuButton
              options={countries}
              selectedOption={selectedCountryId}
              setSelectedOption={setSelectedCountryId}
              placeholder="Elija el país"
              minWidth="100%"
              id="select-country-mkt-campaign"
            />
            <Stack
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              width="100%"
            >
              <Tooltip title="Descargar excel de ejemplo">
                <IconButton onClick={downloadTemplate}>
                  <FileDownload />
                </IconButton>
              </Tooltip>
              <Button
                size="small"
                component="label"
              >
                Subir contactos
                <input
                  type="file"
                  hidden
                  accept=".xlsx"
                  onChange={setContacts}
                />
              </Button>
              {contacts && (
                <>
                  <Typography variant="body1" noWrap>
                    {contacts?.name}
                  </Typography>
                  <IconButton onClick={clearContacts}>
                    <Clear />
                  </IconButton>
                </>
              )}
            </Stack>
          </Stack>
        </form>
      </FingoDialog>
    </>
  );
};

export default CreateCampaignAction;
